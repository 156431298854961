@import '../../styles/colors.scss';
@import '../../styles/mediamixins.scss';


.test {
    background-color: white;
    padding: 16px;
    border-radius: 7px;
    // border: solid;
    // border-width: 1px;
    // border-color: rgb(219, 229, 245);
    color: red;
    box-shadow: 0 4px 8px 0 rgba(44, 39, 56, 0.04);
    font-size: 15px;
    border: solid 1px #e9eef3;
    font-family: 'Poppins-regular';
}

.cvc-container {
    background-color: $lightgraybox;
    padding: 16px;
    border-radius: 4px;
    border: solid;
    border-width: 1px;
    border-color: rgb(219, 229, 245);
    border-left-width: 1px;
}

.strip-powered {
    height: 30px;
}



@include media("<tablet") {
    .credit-card-buttons-container {
        justify-content: center;
        margin-top: 30px;
    }
    
    .credit-card-buttons-container-edit {
        justify-content: center;
        margin-top: 30px;
    }
}
