@import './colors.scss';
@import './variables.scss';

@media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
@font-face {
    font-family: 'AvenirLTPro';
    src: url(../assets/fonts/AvenirLTProBlack.woff);
  }
  
  @font-face{
    font-family: 'Poppins-Bold';
    src: url(../assets/fonts/Poppins-Bold.ttf);
  }
  
  @font-face {
    font-family: 'Avenir-Book';
    src: url(../assets/fonts/AvenirLTProBook.woff);
  }

  @font-face {
      font-family: 'Poppins-Regular';
      src: url(../assets/fonts/Poppins-Regular.ttf);
  }

.MuiPaper-root.MuiAccordion-root::before {
    background-color: transparent;
}
.MuiPaper-root.MuiAccordion-root::after {
    background-color: transparent;
}
.text-small-title {
    @include font-size-map($body-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $black;
    font-weight: normal;
    font-family: 'Poppins-Regular' !important;
}

.no-text-transform {
    text-transform: none;
}

.text-title {
    @include font-size-map($title-font-size);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Poppins-Bold' !important;
}

.checkbox-text{
    font-family: 'Poppins-Regular';
    color: $grey;
    font-size: 16px;
}

.text-promotion {
    @include font-size-map($promotion-size);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Poppins-Bold' !important;
}

.text-promotion-numbers {
    @include font-size-map($numbers-font-size);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.text-body {
    @include font-size-map($secondary-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: 'Poppins-Regular' !important;
}

.coverage-title{
    @include font-size-map($secondary-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: 'Poppins-Bold';
}

.coverage-summary-box {
    margin-bottom: 12px;
}

.text-small {
    @include font-size-map($small-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: 'Poppins-Regular';
}

.text-tiny {
    @include font-size-map($tiny-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.3px;
    font-family: 'Avenir-Book' !important;
}

.textcenter {
    text-align: center;
}

.textleft {
    text-align: left;
}

.textright {
    text-align: right;
}

.bold {
    font-family: 'Poppins-bold';
    font-weight: 600 !important;
}

.clickable {
    cursor: pointer;
}

.underline {
    text-decoration-line: underline !important; 
}

.space-between {
    justify-content: space-between;
}

.account-table-title {
    display: inline-flex;
    align-items: center;
}

.space-evenly { 
    justify-content: space-evenly;
}

.uppercase {
    text-transform: uppercase;
}

hr {
    margin-top: 2vh !important; 
    margin-bottom: 2vh !important;
}

.x-large-icon {
    height: 80px !important;
    width: 80px !important;
}

.big-icon {
    height: 70px !important;
    width: 70px !important;
}

.large-icon {
    height: 40px !important;
    width: 40px !important;
}

.medium-icon {
    height: 28px !important;
    width: 28px !important;
}

.x-medium-icon {
    height: 25px;
    width: 25px;
}

.small-icon {
    height: 20px;
    width: 20px;
}

.tiny-icon {
    width: 18px !important;
    height: 18px !important;
}

.full-width {
    width: 100%;
}

.full-height { 
    height: 100%;
}

.italic {
    font-style: italic;
}

.capitalize {
    text-transform: capitalize;
}

.light { 
    font-weight: 200;
}

.bolder { 
    font-weight: 800 !important;
}

.fit-content {
    width: fit-content
}

.MuiTabs-root {
    overflow: unset !important;
}

.MuiTab-root > .MuiTab-wrapper{
    text-align: left !important;
    align-items: flex-start !important;
}

.MuiAccordionSummary-expandIcon {
    color: $lightgrayblue !important;
}

.MuiGridList-root {
    overflow-y: hidden !important;
}

.text-overflow-elipsis {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-overflow-elipsis-small {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-overflow-elipsis-tiny {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ai-list {
    height: 400px;
    overflow-y: auto;
}

.small-margin-right {
    margin-right: 15px;
}

.text-overflow-small {
    width: 200px;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
}

.transperent {
    background-color: transparent;
}

.auto-horizontal-margin {
    margin-left: auto;
    margin-right: auto;
}
.text-style-bottom-summary {
    @include font-size-map($secondary-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

@media (max-width: 320px) {
    .text-style-bottom-summary {
        @include font-size-map($small-font-size);
    }
}

.text-style-bottom-summary {
    @include font-size-map($secondary-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

@media (max-width: 320px) {
    .text-style-bottom-summary {
        @include font-size-map($small-font-size);
    }
}
@media (max-width: 280px) {
    .auto-horizontal-margin {
        margin-left: 0;
        margin-right: 0;
        font-size: 0.8rem;
    }

    .text-style-bottom-summary {
        @include font-size-map($secondary-font-size);
    }
}

.display-flex-center {
    display: flex;
    align-items: center;
}

