@import './../../styles/colors.scss';
@import './../../styles/variables.scss';

.mobile-tabs-container {
    min-height: calc(48px + 50px);
    margin-top: 56px;
    margin-bottom: 50px;
}

.policy-management-title {
    margin-top: calc(60px + 5vh);
}

.no-policies-text {
    color: #b6bcc7;
    font-size: 1.5em;
    font-family: 'Poppins-Bold';
    margin-top: 2rem;
}