
$grey: #6e6e72;

$gray: #6e6e72;

$dark-grey: #606060;

$lightteal: var(--lightteal);

$verylightteal: var(--verylightteal);

$verylightgray: #f3f8ff;

$lightgray: #dfe7f5;

$bluegrey: var(--bluegrey);

$lightgraybox: var(--lightgraybox);

$lightgrayblue: #9fadc5;

$darkbluegray: #9498a0;

$black: #030c22;

$secondaryblack: #242f40;

$blue: var(--blue);

$superLightBlue: #55ccde;

$skyblue: #3986e9;

$powderblue: var(--powderblue);

$green: #00c48c;

$glowgreen: #0ce0a3;

$greenbackground: rgba(0, 255, 136, 0.55);

$darkblue: var(--darkblue);
 
$darkskyblue: var(--darkskyblue);

$discountbluebackground: #eef4ff;

$backbuttongray: #9cafd0;

$iceblue: #DBEDFB;

$tealshadow: #dbe5f1;

$palegreytwo: #fbfdff;

$palegreythree: #f2f5fc;

$white: #ffffff;

$tealblue: #89B6DE;

$placeholder-text: #8898b5;

$purple: #4117ab;

$purplelight: #e3e5ff;

$yellow: #ffd35e;

$orange: #ff8203;

$blueactive: #0058da; 

$bluehover: #4199ff;

$secondary: var(--secondary);

$buttonactive: var(--buttonactive);

$buttonhover: var(--buttonhover);

$buttonshadow: var(--buttonshadow);
$lightblue: #55ccde;

:export {
    blue: $blue;
    lightteal: $lightteal;
    bluegrey: $bluegrey;
    verylightteal: $verylightteal;
    darkblue: $darkblue;
    primary: $blue;
    secondary: $secondary;
    powderblue: $powderblue;
    purple: $purple;
    yellow: $yellow;
    buttonactive: $buttonactive;
    buttonhover: $buttonhover;
    buttonshadow: $buttonshadow;
}