@import "../../../styles/colors.scss";
@import "../../../styles/mediamixins.scss";

.square-frame-design {
    color: $orange;
    background-color: add-opacity($orange, 0.1);
    width: fit-content;
    height: fit-content;
    padding: 10px;
    margin: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.square-frame-design-purple {
    color: $purple;
    background-color: add-opacity($purple, 0.1);
    width: fit-content;
    height: fit-content;
    padding: 10px;
    margin: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.square-frame-design-grey {
    color: $grey;
    background-color: add-opacity($grey, 0.1);
    width: fit-content;
    height: fit-content;
    padding: 10px;
    margin: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

