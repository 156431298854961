.tab-1 {
    margin-left: 30px;
}

.bearucracy-modal-scroll-bar::-webkit-scrollbar {
    width: 10px;
}

.bearucracy-modal-scroll-bar::-webkit-scrollbar-track {
  background: #dfeaf3;
  border-radius: 5;
  margin-right: 5px;
}
 
.bearucracy-modal-scroll-bar::-webkit-scrollbar-thumb {
  background: #b4c5d4; 
  border-radius: 50px;
}

.bearucracy-modal-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #b4c5d4; 
}

.terms-container {
    p {
        font-size: 0.8em;
    }
}