.quote-application {
    color: #feae46;
}

.quote-declined {
    color: #d00000;
}

.quote-underwriter {
    color: #55ccde;
}

.quote-quoted {
    color: #00c48c;
}

.quote-card-text {
    padding: 2px 0;
    font-size: 0.9em;
}

.quote-card-content { 
    // border-top: 1px solid #ccc;
    margin-top: 10px;
}

.quote-card-header { 
    margin-bottom: 10px;
}

.quote-card-empty-divider {
    margin: 0;
    width: 100%;
    height: 1px;
    background: #ccc;
}


.quote-card-aircraft {
    font-size: 1.15em;
    font-weight: bold;
}

.quote-goto-checkout {
    background-color: #00c48c;
}

.no-quotes-text {
    color: #acbcc5;
    font-size: 1.3em;
    font-weight: bolder;
}