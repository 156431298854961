@import '../../../styles/colors.scss';


.back-button-flow {
    background-color: white !important;
    color: $backbuttongray !important;
    min-width: 100px;
    min-height: 50px;
    border-radius: 25px !important;
    text-transform: none !important;
    border: solid 1px $backbuttongray !important;
}