
.main_container {
    background-color: white;
    width: 95%;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 5px 16px 0 rgba(160, 172, 211, 0.5); 
    position: relative;
    overflow: auto;
}

.circle_container {
    height: 70px; 
    width: 70px;
    border-radius: 80px;
    background-color: white; 
    position: relative;
    top: 30px;
    z-index: 1;
    box-shadow: rgba(160, 172, 211, 0.5) 0px -4px 15px -8px;
}

.close_btn_container {
    position: absolute;
    top: 9px;
    right: 9px;
    cursor: pointer;
}

.circled-popup-content {
    margin-top: 40px;
    width: 100%;
}

.scroll-modal {
    overflow-y: auto;
    height: 75vh;
}