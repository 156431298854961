@import '../../styles/mediamixins.scss';
@import '../../styles/colors.scss';

.layout-card-container {
    background-color: #f0f3f8;
    background-image: url('../../assets/clouds-cover.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media("<=tablet") {
        border-top-left-radius: 50px;
        position: relative;
        top: -50px;
        min-height: 100%;
        width: 100%;
    }
}

.layout-card-content {
    margin-left: 2vw;
    @include media("<=tablet") {
        margin-left: 0;
    }
}


.layout-card-side {
    position: relative;
    background-color: #023E73;
    background-image: linear-gradient(125deg, #071939 0%, rgba(216,216,216,0.00) 100%);
    @include media("<=tablet") {
        min-height: 160px;
    }
}

.layout-card-side-globe {
    position: absolute;
    width: calc(100% - 60px);
    width: -webkit-calc(100% - 60px);
    width: -moz-calc(100% - 60px);
    height: auto;
    bottom: 0;
} 
.layout-card-side-footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 60px);
    width: -webkit-calc(100% - 60px);
    width: -moz-calc(100% - 60px);
}

.layout-card-banner-mobile {
    position: relative;
}

.layout-card-side-content {
    z-index: 100;
    width: calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    margin-left: 30px;
}

.layout-card-footer-globe {
    z-index: 99;
    height: 70vh;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.layout-card-globe-mobile {
    position: absolute;
    height: 100%;
    width: 100%;
}

.layout-card-side-tailend {
    position: absolute;
    height: 100%;
    width: 60px;
    top: 0;
    right: -1px;
    background-color: #f0f3f8;
    border-top-left-radius: 15em 20em;
    @include media("<=tablet") {
        display: none;
    }
}

.layout-container {
    height: 100vh;
    @include media("<=tablet") {
        background-color: transparent;
    }
}

.step-title {
    margin-top: calc(60px + 5vh);
    @include media("<=tablet") {
        text-align: center;
    }
}

.flow-footer {
    padding: 20px;
    font-size: 0.9em;
    @include media("<=tablet") {
        padding: 10px 15px;
        line-height: 1.2;
    }
}