@import '../../styles/colors.scss';

.container {
    border-radius: 3px;
    border: solid 1px $darkbluegray;
    width: fit-content;
    min-width: 120px;
}

.content {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 6px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-no-icon {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 6px;
    padding-left: 8px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.icon-box {
    background-color: $verylightteal;
}

.icon-content-left {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
}

.action-content-right {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-left: solid 1px $darkbluegray;
}

.pencil {
    height: 14px;
    width: 14px;
}