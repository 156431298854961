@import '../../../styles/colors.scss';

.bell-frame {
    background-color: $verylightteal;
    padding: 8px;
    border-radius: 6px;
    width: fit-content;
}

.bell-icone-frame {
    padding: 4px;
    background-color: white;
    margin-right: 6px;
    border-radius: 6px;
}

.regular-frame {
    background-color: #f3f7ff;
    padding: 16px;
    border-radius: 6px;
    width: fit-content;
    color:#8093a6;
    font-size: 15px;
    font-weight: 400;
}