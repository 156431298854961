.margin-0 {
    margin: 0 !important;
}

.padding-0{
    padding: 0!important;
}

.margin-top-small {
    margin-top: 1vh !important; 
}

.margin-top-3 {
    margin-top: 3px;
}

.margin-top-6 {
    margin-top: 6px;
}

.margin-top-8 {
    margin-top: 8px;
}

.margin-top-basic {
    margin-top: 2vh !important;
}

.margin-top-medium {
    margin-top: 3vh !important;
}

.margin-top-large {
    margin-top: 4vh !important;
}

.margin-top-xlarge {
    margin-top: 5vh !important;
}

.margin-top-xxlarge {
    margin-top: 18vh !important;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}


.margin-bottom-small {
    margin-bottom: 1vh;
}

.margin-bottom-basic {
    margin-bottom: 2vh !important;
}

.margin-bottom-medium {
    margin-bottom: 3vh !important;
}

.margin-bottom-large {
    margin-bottom: 4vh !important;
}

.padding-bottom-basic {
    padding-bottom: 2vh;
}

.margin-left-basic {
    margin-left: 2vw !important;
}

.margin-left-medium {
    margin-left: 3vw !important;
}

.margin-left-large {
    margin-left: 4vw !important;
}

.margin-left-16{
    margin-left: 16px;
}

.margin-left-basic-plus {
    margin-left: 4vw !important;
}

.margin-right-basic-plus {
    margin-right: 4vw !important;
}

.margin-left-xlarge {
    margin-left: 19vw !important;
}

.margin-left-6 {
    margin-left: 6px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-left-3 {
    margin-left: 3px;
}

.margin-left-negative {
    margin-left: -16px;
}

.margin-left-negative-medium {
    margin-left: -10px;
}

.margin-right-3 {
    margin-right: 3px;
}

.margin-right-6 {
    margin-right: 6px;
}

.margin-right-small {
    margin-right: 1vw;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-right-small {
    margin-right: 1vw;
}

.margin-left-small {
    margin-left: 1vw;
}

.margin-right-basic {
    margin-right: 2vw;
}

.margin-right-medium {
    margin-right: 3vw;
}

.margin-right-large {
    margin-right: 4vw;
}

.margin-bottom-6 {
    margin-bottom: 6px;
}

.margin-bottom-2 {
    margin-bottom: 2px;
}

.margin-left-12 {
    margin-left: 12px;
}

.margin-bottom-18 {
    margin-bottom: 18px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-left-8 {
    margin-left: 8px;
}

.margin-top-10 {
    margin-top: 10px;
}

.no-margins-sides {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-bottom-10 { 
    padding-bottom: 10px;
}

.padding-left-large { 
    padding-left: 4vw !important;
}
.padding-left-small { 
    padding-left: 1vw !important;
}
.padding-right-small { 
    padding-right: 1vw !important;
}
.padding-right-basic { 
    padding-right: 1vw !important;
}
.padding-left-basic { 
    padding-left: 2vw !important;
}