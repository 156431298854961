@import '../../styles/colors.scss';

.box {
    background-color: $verylightteal;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 6px;
    padding-left: 6px;
    color: $lightteal;
    border-radius: 4px;
    text-align: center;
}