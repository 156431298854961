@import '../../../styles/colors.scss';
@import '../../../styles/mediamixins.scss';

.sky-popver-container {
    padding: 14px;
    width: 25em;
    border: solid 1px $lightgray;
    line-height: 1.4em;
    border-radius: 4px;
}

.sky-popover-paper {
    box-shadow: 0 5px 24px 0 $tealshadow;
}

@include media("<=phone") {
    .sky-popver-container {
        background-color: $blue;
        padding-right: 10px;
        padding-left: 10px;
        width: 95%;
    }   
}