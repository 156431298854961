.input_field_style{
    background: rgba(255, 255, 255, 0.35) !important;
}

.title {
    width: 70%;
}

.footer-mobile {
    height: 70px;
    padding: 12px;
    background-color:  whitesmoke;
    opacity: 0.85;
}

.footer {
    background-color:  whitesmoke;
    opacity: 0.85;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    padding-left: 4vw !important;
    padding-top: 10px;
    padding-bottom: 15px;
}

.qoute_link {
    color: #f67347;
}

.mobile_button {
    width: 100%;
}


