@import '../../../styles/colors.scss';

@import '../../../styles/mediamixins.scss';


.policy-summary-headline {
    background-image: url('../../../assets/policy-summary-plane.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 120px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.header-text {
    position: absolute;
    margin-left: 25px;
}

.insured-text-big {
    font-family: 'Poppins-Regular';
    @include media("<=phone") {
        font-size: 16px;
    }
    font-size: 13px;
    color: #6e707a;
}

.insured-text-small {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    @include media("<=phone") {
        font-size: 14px;
    }
    font-weight: normal;
    color: #6e707a;
}

.aircraft-types {
    width: 82px;
    height: 17px;
    margin: 7px 85px 7px 3px;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e707a;
}

.aircraft-selected-type {
    width: 19px;
    height: 17px;
    margin: 7px 2px 7px 85px;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e707a;
}

.quote-coverage-title {
    width: 150px;
    height: 15px;
    margin: 7px 0px 7px 3px;
    font-family: 'Poppins-Regular';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e707a;
    padding-bottom: 5px;
}

.coverage-price {
    width: 29px;
    height: 15px;
    margin: 7px 2px 7px 98px;
    font-family: 'Poppins-Regular';
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e707a;
}

.policy-summary {
    border-radius: 8px;
    width: 280px;
    padding-bottom: 20px;

}

.credit-card-alert {
    margin-top: 10px;
    width: 280px;
    color: red
}

.policy-summary-continue {
    margin-left: 0 !important;
}

.policy-summary-container {
    position: sticky;
    margin-top: 2vh;
    top: 90px;
}

.policy-summary-sum {
    padding-bottom: 10px;
    border-bottom: 1px solid;
}

.payment-summary-sum {
    text-align: right;
    margin: auto 0px auto auto;
    width: 33%;
    padding-bottom: 10px;
    border-bottom: 1px solid;
}

.number-list {
    margin-left: 16px;
    padding: 0;
}

@include media("<=phone") {
    .policy-summary-container {
        width: 100%;
        margin-top: 0 !important;
    }
}

.base {
    width: 300px;
    margin: 0px 25px 29.5px 23px;
    padding: 16px 13px 21px 15px;
    border-radius: 24px;
    box-shadow: 0 24px 48px 0 rgba(44, 39, 56, 0.04), 0 12px 24px 0 rgba(44, 39, 56, 0.02);
    background-color: #fff;
}

.total-price-title {
    font-size: 14px;
    @include media("<=phone") {
        font-size: 15px;
    }
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #164076;
}

.total-price-subtitle {
    font-size: 0.85em;
    color: #164076;
}

.total-price {
    width: 37px;
    height: 17px;
    margin: 0px 0 9px 98px;
    font-family: 'Poppins-Bold';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #164076;
}

.total-surcharge-title {
    font-size: 0.7em;
    @include media("<=phone") {
        font-size: 15px;
    }
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #164076;
}

.your-policy {
    width: 93px;
    height: fit-content;
    margin: 0 0 7px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #55ccde;
    position: absolute;
}

.start-date {
    width: 73px;
    height: 40px;
    margin: 7px 20px 0 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
}